import { type AuctionInfo } from '@/shared/hooks/useAuction';

type Props = {
  auction: AuctionInfo;
} & React.SVGProps<SVGSVGElement>;

const DASH_ARRAY_UNITS = 400;
const calculateDashOffset = (percent: number) =>
  DASH_ARRAY_UNITS - (DASH_ARRAY_UNITS * percent) / 100;

export const AnimatedEpochProgress = ({ auction, ...rest }: Props): JSX.Element => {
  const epochProgressPercent = auction.percentageElapsed;
  const auctionProgressPercent = Math.max(
    0,
    auction.percentageElapsed - (100 - auction.redemptionPeriodAsPercentage),
  );

  const noAuction = auction.hasEnded || !auction.authorityRotationEnabled;

  return (
    <svg
      width="188"
      height="188"
      viewBox="0 0 188 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="94.0003" cy="94.0003" r="80.1311" fill="#111111" />
      <circle
        cx="94.0003"
        cy="94.0003"
        r="80.1311"
        fill="url(#paint0_radial_4444_10793)"
        fillOpacity="0.14"
      />

      <circle />

      {noAuction ? (
        <>
          <mask id="path-2-inside-1_4524_56264" fill="white">
            <path d="M94.1316 17.6916C94.1316 15.6528 95.7856 13.9911 97.8222 14.085C117.656 14.9987 136.497 23.2518 150.637 37.3143C165.652 52.2467 174.145 72.5152 174.261 93.6906C174.378 114.866 166.108 135.227 151.258 150.323C136.409 165.42 116.187 174.025 95.0126 174.257C73.8381 174.49 53.4323 166.332 38.2544 151.566C23.0764 136.799 14.3605 116.626 14.0113 95.4527C13.6621 74.2798 21.7079 53.8294 36.3905 38.5706C50.218 24.2005 68.8728 15.5351 88.6816 14.1855C90.7157 14.0469 92.4059 15.6718 92.4507 17.7101C92.4956 19.7484 90.8781 21.4274 88.8446 21.5755C70.9873 22.8761 54.1852 30.7259 41.7108 43.6899C28.381 57.5428 21.0765 76.1089 21.3935 95.3309C21.7106 114.553 29.6234 132.868 43.4029 146.274C57.1823 159.68 75.7079 167.086 94.9314 166.875C114.155 166.663 132.513 158.851 145.995 145.146C159.476 131.44 166.984 112.956 166.878 93.7312C166.773 74.5068 159.062 56.1059 145.43 42.5494C132.674 29.8629 115.703 22.3844 97.8218 21.4768C95.7856 21.3735 94.1316 19.7305 94.1316 17.6916Z" />
          </mask>
          <path
            d="M94.1316 17.6916C94.1316 15.6528 95.7856 13.9911 97.8222 14.085C117.656 14.9987 136.497 23.2518 150.637 37.3143C165.652 52.2467 174.145 72.5152 174.261 93.6906C174.378 114.866 166.108 135.227 151.258 150.323C136.409 165.42 116.187 174.025 95.0126 174.257C73.8381 174.49 53.4323 166.332 38.2544 151.566C23.0764 136.799 14.3605 116.626 14.0113 95.4527C13.6621 74.2798 21.7079 53.8294 36.3905 38.5706C50.218 24.2005 68.8728 15.5351 88.6816 14.1855C90.7157 14.0469 92.4059 15.6718 92.4507 17.7101C92.4956 19.7484 90.8781 21.4274 88.8446 21.5755C70.9873 22.8761 54.1852 30.7259 41.7108 43.6899C28.381 57.5428 21.0765 76.1089 21.3935 95.3309C21.7106 114.553 29.6234 132.868 43.4029 146.274C57.1823 159.68 75.7079 167.086 94.9314 166.875C114.155 166.663 132.513 158.851 145.995 145.146C159.476 131.44 166.984 112.956 166.878 93.7312C166.773 74.5068 159.062 56.1059 145.43 42.5494C132.674 29.8629 115.703 22.3844 97.8218 21.4768C95.7856 21.3735 94.1316 19.7305 94.1316 17.6916Z"
            fill="url(#paint1_linear_4524_56264)"
            stroke="url(#paint2_linear_4524_56264)"
            strokeWidth="2"
            mask="url(#path-2-inside-1_4524_56264)"
          />
          <mask id="path-3-inside-2_4524_56264" fill="white">
            <path d="M94.1312 155.646C94.1312 157.417 92.6951 158.86 90.9269 158.772C75.3346 158 60.504 151.611 49.2127 140.726C37.1504 129.098 30.0674 113.246 29.4534 96.5023C28.8394 79.7591 34.7423 63.4301 45.9205 50.9498C56.3842 39.2672 70.707 31.8091 86.2009 29.8968C87.9579 29.6799 89.4959 31.0143 89.6256 32.7798V32.7798C89.7552 34.5454 88.427 36.0727 86.6712 36.2992C72.8331 38.0837 60.0522 44.7809 50.696 55.227C40.625 66.4711 35.3068 81.1826 35.86 96.2674C36.4132 111.352 42.7946 125.635 53.6621 136.111C63.7583 145.844 76.9954 151.587 90.927 152.353C92.6947 152.45 94.1312 153.876 94.1312 155.646V155.646Z" />
          </mask>
          <path
            d="M94.1312 155.646C94.1312 157.417 92.6951 158.86 90.9269 158.772C75.3346 158 60.504 151.611 49.2127 140.726C37.1504 129.098 30.0674 113.246 29.4534 96.5023C28.8394 79.7591 34.7423 63.4301 45.9205 50.9498C56.3842 39.2672 70.707 31.8091 86.2009 29.8968C87.9579 29.6799 89.4959 31.0143 89.6256 32.7798V32.7798C89.7552 34.5454 88.427 36.0727 86.6712 36.2992C72.8331 38.0837 60.0522 44.7809 50.696 55.227C40.625 66.4711 35.3068 81.1826 35.86 96.2674C36.4132 111.352 42.7946 125.635 53.6621 136.111C63.7583 145.844 76.9954 151.587 90.927 152.353C92.6947 152.45 94.1312 153.876 94.1312 155.646V155.646Z"
            fill="#424242"
            stroke="#4F4F4F"
            strokeWidth="3.08197"
            mask="url(#path-3-inside-2_4524_56264)"
          />
        </>
      ) : (
        <>
          <mask id="path-2-inside-2_5350_8618" fill="white">
            <path d="M94.0004 155.515C94.0004 157.286 92.5642 158.729 90.7961 158.642C75.2037 157.869 60.3731 151.48 49.0819 140.596C37.0195 128.967 29.9365 113.115 29.3225 96.3714C28.7085 79.6282 34.6114 63.2993 45.7897 50.8189C56.2533 39.1364 70.5761 31.6783 86.07 29.7659C87.827 29.5491 89.365 30.8834 89.4947 32.649C89.6244 34.4146 88.2961 35.9419 86.5403 36.1683C72.7022 37.9529 59.9213 44.65 50.5651 55.0961C40.4941 66.3403 35.1759 81.0517 35.7291 96.1365C36.2823 111.221 42.6637 125.504 53.5312 135.98C63.6274 145.713 76.8645 151.456 90.7962 152.222C92.5638 152.319 94.0004 153.745 94.0004 155.515Z" />
          </mask>
          <path
            d="M94.0004 155.515C94.0004 157.286 92.5642 158.729 90.7961 158.642C75.2037 157.869 60.3731 151.48 49.0819 140.596C37.0195 128.967 29.9365 113.115 29.3225 96.3714C28.7085 79.6282 34.6114 63.2993 45.7897 50.8189C56.2533 39.1364 70.5761 31.6783 86.07 29.7659C87.827 29.5491 89.365 30.8834 89.4947 32.649C89.6244 34.4146 88.2961 35.9419 86.5403 36.1683C72.7022 37.9529 59.9213 44.65 50.5651 55.0961C40.4941 66.3403 35.1759 81.0517 35.7291 96.1365C36.2823 111.221 42.6637 125.504 53.5312 135.98C63.6274 145.713 76.8645 151.456 90.7962 152.222C92.5638 152.319 94.0004 153.745 94.0004 155.515Z"
            fill="#424242"
            stroke="#4F4F4F"
            strokeWidth="3.08197"
            mask="url(#path-2-inside-2_5350_8618)"
          />

          <mask id="path-1-inside-1_5350_8618" fill="white">
            <path d="M94.0007 17.5608C94.0007 15.5219 95.6547 13.8603 97.6914 13.9541C117.462 14.8649 136.249 23.0687 150.376 37.0541C165.379 51.9072 173.915 72.0813 174.128 93.1924C174.341 114.303 166.213 134.646 151.512 149.798C136.811 164.951 116.724 173.689 95.6164 174.115C74.5086 174.541 54.0856 166.619 38.7855 152.072C23.4854 137.524 14.5447 117.526 13.9062 96.4237C13.2677 75.3212 20.983 54.8194 35.3755 39.3734C48.9272 24.8298 67.3676 15.8752 87.0856 14.168C89.1168 13.9921 90.8364 15.5858 90.9186 17.6229C91.0009 19.6601 89.4144 21.3685 87.384 21.5538C69.6105 23.1764 53.0027 31.2865 40.7773 44.4067C27.7108 58.4295 20.7064 77.0423 21.2861 96.2004C21.8657 115.359 29.9827 133.514 43.873 146.721C57.7633 159.928 76.3046 167.12 95.4676 166.733C114.631 166.347 132.867 158.413 146.213 144.657C159.56 130.901 166.938 112.433 166.745 93.2668C166.552 74.1009 158.802 55.7856 145.181 42.3011C132.437 29.6845 115.515 22.2506 97.6909 21.346C95.6547 21.2426 94.0007 19.5996 94.0007 17.5608Z" />
          </mask>
          <path
            d="M94.0007 17.5608C94.0007 15.5219 95.6547 13.8603 97.6914 13.9541C117.462 14.8649 136.249 23.0687 150.376 37.0541C165.379 51.9072 173.915 72.0813 174.128 93.1924C174.341 114.303 166.213 134.646 151.512 149.798C136.811 164.951 116.724 173.689 95.6164 174.115C74.5086 174.541 54.0856 166.619 38.7855 152.072C23.4854 137.524 14.5447 117.526 13.9062 96.4237C13.2677 75.3212 20.983 54.8194 35.3755 39.3734C48.9272 24.8298 67.3676 15.8752 87.0856 14.168C89.1168 13.9921 90.8364 15.5858 90.9186 17.6229C91.0009 19.6601 89.4144 21.3685 87.384 21.5538C69.6105 23.1764 53.0027 31.2865 40.7773 44.4067C27.7108 58.4295 20.7064 77.0423 21.2861 96.2004C21.8657 115.359 29.9827 133.514 43.873 146.721C57.7633 159.928 76.3046 167.12 95.4676 166.733C114.631 166.347 132.867 158.413 146.213 144.657C159.56 130.901 166.938 112.433 166.745 93.2668C166.552 74.1009 158.802 55.7856 145.181 42.3011C132.437 29.6845 115.515 22.2506 97.6909 21.346C95.6547 21.2426 94.0007 19.5996 94.0007 17.5608Z"
            fill="#424242"
            stroke="#4F4F4F"
            strokeWidth="3.08197"
            mask="url(#path-1-inside-1_5350_8618)"
          />
        </>
      )}

      {/* Epoch circle */}
      {!noAuction && epochProgressPercent !== 0 && (
        <circle
          cx="94.0003"
          cy="94.0003"
          r="76.5"
          strokeWidth="6"
          strokeLinecap="round"
          fill="transparent"
          stroke="url(#paint1_linear_4444_10776)"
          strokeDashoffset={calculateDashOffset(epochProgressPercent)}
          pathLength="408"
          style={{
            strokeDasharray: `${DASH_ARRAY_UNITS}, 408`,
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(-87deg)',
            transformOrigin: '50% 50%',
          }}
        />
      )}
      {/* Auction circle */}
      {!noAuction && auctionProgressPercent !== 0 && (
        <circle
          cx="94"
          cy="94"
          r="61.5"
          strokeWidth="6"
          strokeLinecap="round"
          fill="transparent"
          stroke="url(#paint1_linear_4444_10793)"
          strokeDashoffset={calculateDashOffset(auctionProgressPercent)}
          pathLength="414"
          style={{
            strokeDasharray: `${DASH_ARRAY_UNITS}, 414`,
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(91deg)',
            transformOrigin: '50% 50%',
          }}
        />
      )}

      <defs>
        <radialGradient
          id="paint0_radial_4444_10793"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(94.0003 -41.6063) rotate(90) scale(155.639)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>

        {/* Epoch gradients */}
        <linearGradient
          id="paint1_linear_4444_10776"
          x1="174.124"
          y1="95.076"
          x2="13.8757"
          y2="92.9245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#46DA93" />
          <stop offset="1" stopColor="#1C6642" />
        </linearGradient>
        {/* Auction gradients */}
        <linearGradient
          id="paint1_linear_4444_10793"
          x1="158.722"
          y1="93.9996"
          x2="29.279"
          y2="93.9996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA9F46" />
          <stop offset="0.833333" stopColor="#DA9F46" />
        </linearGradient>
        {/* Rotation gradients */}
        <linearGradient
          id="paint1_linear_4524_56264"
          x1="174.263"
          y1="94.1311"
          x2="14.0004"
          y2="94.1311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={auction.authorityRotationEnabled ? '#7D44C8' : '#DA4646'} />
          <stop offset="1" stopColor={auction.authorityRotationEnabled ? '#C985E9' : '#662D1C'} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4524_56264"
          x1="16"
          y1="78"
          x2="162.5"
          y2="145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={auction.authorityRotationEnabled ? '#CC98EA' : '#B74343'} />
          <stop offset="1" stopColor={auction.authorityRotationEnabled ? '#71369E' : '#B74343'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
