// FLIP UI KIT
const Frame = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      d="M19.25 12.5C19.25 13.4521 19.0625 14.3948 18.6981 15.2745C18.3338 16.1541 17.7997 16.9533 17.1265 17.6265C16.4533 18.2997 15.6541 18.8338 14.7745 19.1981C13.8948 19.5625 12.9521 19.75 12 19.75C11.0479 19.75 10.1052 19.5625 9.22554 19.1981C8.34593 18.8338 7.5467 18.2997 6.87348 17.6265C6.20025 16.9533 5.66622 16.1541 5.30187 15.2745C4.93753 14.3948 4.75 13.4521 4.75 12.5C4.75 10.5772 5.51384 8.73311 6.87348 7.37348C8.23311 6.01384 10.0772 5.25 12 5.25C13.9228 5.25 15.7669 6.01384 17.1265 7.37348C18.4862 8.73311 19.25 10.5772 19.25 12.5Z"
      stroke="#5F5F5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Frame;
