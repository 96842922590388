import { formatISO } from 'date-fns';

export const formatRelativeDateInPast = (timestamp: string | number): string => {
  const happenedAt = new Date(Number(timestamp) || 0);
  const now = new Date();

  if (now < happenedAt) throw new Error('timestamp represents future time');

  return formatISO(happenedAt, { representation: 'date' });
};
